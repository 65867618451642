<template>

  <HomePage />

</template>

<script>
import HomePage from '@/pages/HomePage'
export default {
  components: {
    HomePage,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
