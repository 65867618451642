<template>

<div class="bg-white min-h-screen">
  <div class="flex justify-center items-center min-h-screen">Main column</div>
</div>

</template>

<script>
export default {
  data: function () {
    return {
    }
  },
}
</script>
