import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { HomeIcon, Bars4Icon, ClockIcon } from '@heroicons/vue/24/outline';

const icon = {
  install(app) {
    app.config.globalProperties.$icon = {
      MagnifyingGlassIcon,
      HomeIcon,
      Bars4Icon,
      ClockIcon,
    };
  },
};

export default icon;
